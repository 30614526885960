/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class ContentPolicy extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Politique de contenus - Flowfo')}
          {seo.description(
            "Avoir l'esprit ouvert ne signifie pas que nous n'avons pas de règles. Tous les détails dans cette page.",
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW POLITIQUE CONTENU FRANCAIS */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Tue Dec 29 2020 18:04:49 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Contenu - FRANCAIS</title>
          <meta content="Contenu - FRANCAIS" property="og:title" />
          <meta content="Contenu - FRANCAIS" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.59156891b.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages">
              <a href="https://flowfo.me/content-policy" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt=""
                  className="drapeauxronds"
                />
                <h2 className="changelangue">read in english</h2>
              </a>
            </div>
            <div className="divwithcontent">
              <h1 className="webflowheading1">Politique de contenus</h1>
              <h3 className="webflowheading3">Authenticité</h3>
              <p className="webflowparagraphe">
                <strong>
                  Flowfo est à destination des créateurs qui offrent au monde quelque chose d'original dont ils sont les
                  auteurs.
                </strong>
                <br />
                <strong>
                  Nous considérons la satire, la caricature et les univers alternatifs comme des créations importantes.{' '}
                </strong>
                <br />
                Mais en tant que créateur, vous ne pouvez pas publier de travaux qui enfreignent les droits d'autres
                personnes.
                <br />
                Vous ne pouvez pas créer de fausses pages ou collecter de l'argent pour quelque chose dont vous n'êtes
                pas l'auteur. Vous ne pouvez pas créer de fan page pour le compte de quelqu'un d'autre.
                <br />
                Vous ne pouvez pas représenter quelqu'un d'autre en utilisant son nom, sa marque ou ses travaux.
                <br />
                Vous ne pouvez pas collecter de fonds pour ne "pas faire" quelque chose. Par exemple : "j'arrêterai de
                plaisanter sur quelqu'un si j'atteins 200€ par mois."
                <br />
              </p>
              <h3 className="webflowheading3">Intimidation, harcèlement et menaces</h3>
              <p className="webflowparagraphe">
                Flowfo doit rester un endroit sécurisant pour toutes nos communautés. <br />
                Nous n'autorisons ni l'intimidation, ni le harcèlement, ni la menace.
                <br />
                Il n'y a pas de place sur Flowfo pour les discours haineux, les appels à la violence, l'exclusion ou la
                ségrégation.
                <br />
                Aucune information à caractère privée (téléphone, adresse...) et appartenant à une tierce personne ne
                peut être divulguée sur la plateforme.
                <br />
                Une saute d'humeur est toujours possible mais les insultes récurrentes sont interdites.
                <br />
              </p>
              <h3 className="webflowheading3 red">Contenus pour adultes - NSFW</h3>
              <p className="webflowparagraphe">
                NSFW est l'abréviation de "Not Safe For Work", décrivant du "contenu pour adultes".
                <br />
                Les œuvres avec contenu pour adultes doivent être marquées comme tel dès lors qu'elles présentent un
                contenu anormalement violent, parlant de sexe de façon cru ou représentant des scènes de sexe.
                <br />
                Le langage grossier communément admis n'est pas NSFW.
                <br />‍<br />
                Toute œuvre NSFW reste référencée sur Flowfo mais elle fait l'objet d'un avertissement explicite.
                <br />
                <strong>
                  Les bannières, les couvertures qui identifient les œuvres, les images de profil utilisateur ne peuvent
                  en aucun cas être NSFW car elles sont visibles par tous les publics.
                  <br />
                  La représentation des parties génitales, de scènes gores est ainsi interdite sur ces éléments.
                </strong>
                ‍<br />‍<br />
                En cas de doute sur le contenu d'une œuvre, nous conseillons aux créateurs de déclarer cette dernière
                comme NSFW.
                <br />
                Pour rappel, il est tout à fait possible de créer d'autres artworks destinés à des public différents.{' '}
              </p>
              <h3 className="webflowheading3 red">Nudité et pornographie</h3>
              <p className="webflowparagraphe">
                Vous pouvez publier des créations représentant des personnages nus, qu'ils soient réels ou représentés,
                mais ces œuvres doivent être marquées comme NSFW (= avec contenu pour adultes).
                <br />
                Les scènes de sexe sont autorisées (avec marquage NSFW) dès lors qu'elles prennent place dans une
                histoire ou ont un caractère artistique manifeste (pour des photo par exemple). <br />
                La pornographie (masturbation ou scènes de sexe devant un objectif) est interdite.
                <br />
                Les services sexuels et la prostitution sont interdits.
                <br />
              </p>
              <h3 className="webflowheading3 red">Lutte contre la pédophilie</h3>
              <p className="webflowparagraphe">
                La représentation de mineurs impliqués dans des scènes de sexe est strictement interdite. <br />
                Cette interdiction est valable pour tous les médias : photos, vidéos, dessins, textes, que le mineur
                soit réel, représenté ou stylisé. Cette interdiction est aussi valable si le créateur est le sujet de
                l'œuvre.
                <br />
                <strong>Pour les mangas, le genre Shotacon' ou Shota est interdit.</strong>
                <br />
                <br />
                Afin de préserver la sécurité des mineurs, nous sommes susceptibles de déclarer aux autorités tout
                contenu présentant un caractère pédophile.
                <br />
              </p>
              <h3 className="webflowheading3">Créations hors normes</h3>
              <p className="webflowparagraphe">
                La glorification de la violence, du viol, le cannibalisme, le gore extrême, l'inceste, la nécrophilie,
                l'urophilie, la scatologie sont globalement interdits.
                <br />
                Ces thèmes/pratiques faisant partie de la vraie vie, nous comprenons toutefois que les créateurs
                puissent avoir à les aborder. Mais cela doit rester exceptionnel et contextualisée dans une œuvre plus
                globale.
                <br />
              </p>
              <h3 className="webflowheading3 violet">Activités nuisibles et illégales</h3>
              <p className="webflowparagraphe">
                Vous ne pouvez pas promouvoir des activités illégales telles que les crimes contre autrui ou contre les
                biens, la distribution d'armes illégales ou la fabrication/vente de drogues.
                <br />
                Les pages qui promeuvent l'automutilation, le suicide ou un trouble de l'alimentation sont interdites.
                <br />
                Tout soutien à des groupes terroristes est interdit. <br />
                Tout financement à destination d'un groupe politique, d'une campagne politique est interdit.
                <br />
                Les cascades dangereuses filmées sont interdites.
              </p>
              <h3 className="webflowheading3">Spam</h3>
              <p className="webflowparagraphe">
                Ne postez pas de commentaires répétitifs et n’envoyez pas de messages privés indésirables à d’autres
                membres de la communauté.{' '}
              </p>
              <h3 className="webflowheading3">Comptes enfreignant notre politique de contenu</h3>
              <p className="webflowparagraphe">
                Nous prendrons des mesures contre un compte si nous constatons qu'il est en violation de ces directives.
                <br />
                Ces mesures incluent la suppression de fichiers, de pitchs, descriptions, commentaire, la suppression de
                Flow, de page ou de profil.
                <br />
                Nous ne partagerons aucun détail lié au type d'action que nous aurions pu prendre contre un compte.{' '}
                <br />
                Cette politique fait partie des conditions générales de Flowfo. <br />
                Pour plus d'informations, contactez Flowfo à{' '}
                <a
                  href="mailto:contact@flowfo.me?subject=A%20propos%20du%20contenu"
                  target="_self"
                  className="webflowlink"
                >
                  contact@flowfo.me
                </a>
                .
              </p>
              <div className="divendblank" />
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW CONTENU POLITIQUE FRANCAIS */}
        </div>
      </>
    );
  }
}

export default ContentPolicy;
